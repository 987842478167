<template>
  <div class="home" v-loading="loading" element-loading-text="模型加载中">
    <canvas id="three" :style="{ height: autoBool ? 'calc(100% - 129px)' : '100%' }"></canvas>
    <div class="cutBox" v-if="autoBool">
      <div
        class="cutImg"
        :class="[setIndex == index ? 'setCutImg' : '']"
        v-for="(item, index) in imgList"
        :key="index"
        @click="cutImage(index)">
        <div class="img" :style="{ 'background-image': 'url(' + item.img + ')' }"></div>
        <div class="title">{{ item.title }}</div>
      </div>
    </div>
  </div>
</template>

<script>
//调试1：http://localhost:8080/3D?url=https%3A%2F%2Ffdoc.oss-cn-shenzhen.aliyuncs.com%2Fdoc%2F112_1700112820503%2Fname_renzhe.glb
//调试2：http://localhost:8080/3D?url=https%3A%2F%2Ffdoc.oss-cn-shenzhen.aliyuncs.com%2Fdoc%2F112_1702611116761.glb%2Fname_guzhuangnv%201211.glb
//参考资料：https://zhuanlan.zhihu.com/p/560325896

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  data() {
    return {
      loading: true,
      imgList: [
        {
          img: "https://fimage.huaxiyou.cc/baseMap1.png",
          title: "默认",
        },
        {
          img: "https://fimage.huaxiyou.cc/baseMap2.png",
          title: " 炫光",
        },
        {
          img: "https://fimage.huaxiyou.cc/baseMap3.png",
          title: "机甲",
        },
        {
          img: "https://fimage.huaxiyou.cc/baseMap4.png",
          title: "粉彩",
        },
        // ,{
        //   img:'https://fimage.oss-cn-shenzhen.aliyuncs.com/baseMap5.png',
        //   title:'默认'
        // }
      ],
      setIndex: 0,
      autoBool: false,
    };
  },
  mounted() {
    this.autoBool = this.$route.query && this.$route.query.autoBool ? this.$route.query.autoBool : false;
    // let aa = "https://onekit.cn/examples/safa/1/index.glb";
    // let bb = "http://flive.oss-cn-shanghai.aliyuncs.com/qxwx/1694157611196.glb";
    // let cc = "http://flive.oss-cn-shanghai.aliyuncs.com/qxwx/1694157780573.obj"
    let url = this.$route.query.url ? decodeURIComponent(this.$route.query.url) : "";
    console.log("https://fdoc.oss-cn-shenzhen.aliyuncs.com/doc/112_1702364548586/name_hsn.glb");
    this.initThree(url);
  },
  methods: {
    async initThree(url) {
      var that = this;
      that.loading = true;
      // 场景
      const scene = new THREE.Scene();
      // 给场景添加颜色
      scene.background = new THREE.Color(0x333333);
      // 增加雾化效果 参数1 雾化颜色 参数2
      // scene.fog = new THREE.Fog("#eee", 20, 50);
      
      
      const canvas = document.querySelector("#three");
      //渲染器
      const renderer = new THREE.WebGLRenderer({ canvas, antialias: true });
      // 开起渲染器阴影效果
      renderer.shadowMap.enabled = true;
      renderer.setPixelRatio(window.devicePixelRatio);

      // renderer.toneMapping = THREE.ACESFilmicToneMapping;
      // renderer.physicallyCorrectLights = true;

      // 摄像机
      const camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 0.1, 1000);
      // 移动照相位置 （照相机最初位置在原点）
      camera.position.z = 30 
      camera.position.x = 0;
      camera.position.y = -3;

      // camera.position.z = 10;
      // camera.position.y = 0;

      var manager = new THREE.LoadingManager();
      var loader = "";

      if (url.indexOf(".glb") != -1) {
        // 监听模型加载完成事件
        manager.onLoad = function () {
          console.log("模型加载完成！");
        };

        // 创建模型加载器
        loader = new GLTFLoader();
        // 加载模型
        loader.load(
          url,
          (gltf) => {
            let model = gltf.scene;
            function animate() {
              model.rotateY(Math.PI / 720); // 每一帧旋转1度（约0.017弧度）
              renderer.render(scene, camera);
              requestAnimationFrame(animate);
            }
            if (that.$route.query && that.$route.query.autoBool) {
              animate();
            }

            scene.add(model);

            // 自适应调整模型大小
            let box = new THREE.Box3().setFromObject(model);
            const size = box.getSize(new THREE.Vector3()); // 获取模型的大小
            let maxDimension = Math.max(size.x, size.y, size.z);
            let scale = 5 / maxDimension;
            model.scale.set(scale, scale, scale);

            const scaleBox = new THREE.Box3().setFromObject(model);
            const center = scaleBox.getCenter(new THREE.Vector3());
            model.position.x += model.position.x - center.x;
            model.position.y += model.position.y - center.y;
            model.position.z += model.position.z - center.z;
          },
          () => {
            that.loading = false;
            console.log("模型加载完成！");
          },
          (err) => {
            that.loading = false;
            console.log(err);
          }
        );
      } else if (url.indexOf(".obj") != -1) {
        // 创建模型加载器
        loader = new OBJLoader();
        // 加载模型
        loader.load(
          url,
          (gltf) => {
            console.log('gltf',gltf.rotation.rotateY)
            let model = gltf
            function animate() {
              requestAnimationFrame(animate);
              // 旋转模型
              if (gltf) {
                gltf.rotation.y += 0.01;
              }
              renderer.render(scene, camera);
            }
            animate()
            scene.add(gltf);

            // 自适应调整模型大小
            let box = new THREE.Box3().setFromObject(model);
            const size = box.getSize(new THREE.Vector3()); // 获取模型的大小
            let maxDimension = Math.max(size.x, size.y, size.z);
            let scale = 5 / maxDimension;
            model.scale.set(scale, scale, scale);

            const scaleBox = new THREE.Box3().setFromObject(model);
            const center = scaleBox.getCenter(new THREE.Vector3());
            model.position.x += model.position.x - center.x;
            model.position.y += model.position.y - center.y;
            model.position.z += model.position.z - center.z;
          },
          () => {
            that.loading = false;
            // console.log(e)
          },
          (err) => {
            this.loading = false;
            console.log(err);
          }
        );
      }

      /** */
      // 创建环境光
      const ambientLight = new THREE.AmbientLight(0xff9900, 0.6); // 颜色和强度
      ambientLight.position.set(-0.615, -0.053, 0.002);
      scene.add(ambientLight);

      // const directionalLight1 = new THREE.DirectionalLight(0xf4e0d1, 2, 100); // 颜色和强度
      // directionalLight1.position.set(10, 10, 10);
      // scene.add(directionalLight1);

      // const directionalLight2 = new THREE.DirectionalLight(0xf4e0d1, 2, 100); // 颜色和强度
      // directionalLight2.position.set(-10, 10, 10);
      // scene.add(directionalLight2);

      let d = 8.25;
      let dirLight = new THREE.DirectionalLight(0xFFE4B5, 3.54);
      dirLight.position.set(-8, 12, 8);
      dirLight.castShadow = true;
      dirLight.shadow.mapSize = new THREE.Vector2(1024, 1024);
      dirLight.shadow.camera.near = 0.1;
      dirLight.shadow.camera.far = 1500;
      dirLight.shadow.camera.left = d * -1;
      dirLight.shadow.camera.right = d;
      dirLight.shadow.camera.top = d;
      dirLight.shadow.camera.bottom = d * -1;
      scene.add(dirLight);

      // 创建半球光
      // const hemisphereLight = new THREE.HemisphereLight(0x00aaff, 0xffffff, 1); // 颜色和强度
      // const hemisphereLight = new THREE.HemisphereLight(0x00aaff, 0xF3F3F1, 0.8); // 颜色和强度
      // scene.add(hemisphereLight);
      
      let hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.61);
      hemiLight.position.set(0, 50, 0);
      scene.add(hemiLight);

      // 创建聚光灯
      const spotLight = new THREE.SpotLight(0xffffff, 3); // 颜色和强度
      spotLight.position.set(-2.7, 12, 13);
      scene.add(spotLight);

      // 相机控件OrbitControls.js可以对Threejs的三维场景进行缩放、平移、旋转操作，本质上改变的并不是场景，而是相机的参数
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = false;
      controls.minPolarAngle = 0;
      controls.maxPolarAngle = Math.PI;

      controls.minDistance = 6; // 最小距离
      controls.maxDistance = 8; // 最大距离

      if (this.$route.query && this.$route.query.autoBool) {
        controls.enabled = false;
        controls.enableZoom = false; //禁止放大
        controls.enablePan = false; //禁止移动

        controls.minDistance = 4; // 最小距离
        controls.maxDistance = 8; // 最大距离

        await addBgImg(scene);
        this.loading = false
      }
      animate();

      function animate() {
        // 更新控制器
        controls.update();
        renderer.render(scene, camera);
        requestAnimationFrame(animate);

        if (resizeRendererToDisplaySize(renderer)) {
          const canvas = renderer.domElement;
          camera.aspect = canvas.clientWidth / canvas.clientHeight;
          // 更新场景的渲染
          camera.updateProjectionMatrix();
        }
      }

      // 原因是设备的物理像素分辨率与CSS像素分辨率的比值的问题，我们的canvas绘制出来后图片因为高清屏设备的影响，导致图片变大，然而我们在浏览器的渲染窗口并没有变大，因此图片会挤压缩放使得canvas画布会变得模糊。
      function resizeRendererToDisplaySize(renderer) {
        const canvas = renderer.domElement;
        var width = window.innerWidth;
        var height = window.innerHeight;
        // devicePixelRatio此属性返回当前显示设备的物理像素分辨率与CSS像素分辨率的比值。该值也可以被解释为像素大小的比例：即一个CSS像素的大小相对于一个物理像素的大小的比值。
        var canvasPixelWidth = canvas.width / window.devicePixelRatio;
        var canvasPixelHeight = canvas.height / window.devicePixelRatio;

        const needResize =
          canvasPixelWidth !== width || canvasPixelHeight !== height;
        if (needResize) {
          renderer.setSize(width, height, false);
        }
        return needResize;
      }

      function addBgImg(scene) {
        return new Promise((reslove, reject) => {
          try {
            const canvas = renderer.domElement;
            const width = canvas.clientWidth/window.devicePixelRatio;
            const height = canvas.clientHeight/window.devicePixelRatio;
            // 加载纹理
            const textureLoader = new THREE.TextureLoader();
            const texture = textureLoader.load(
              that.imgList[0].img,
              function () {
                const material = new THREE.MeshBasicMaterial({ map: texture });
                that.textureLoader = textureLoader;
                that.material = material;
                // 创建几何体
                const aspect = width / height; // 获取相机视口的宽高比
                const geometry = new THREE.PlaneGeometry(aspect * 21, 21); // 将几何体的宽高比设置为相机视口的宽高比，并将几何体的大小设置为相机视口的大小
                const mesh = new THREE.Mesh(geometry, material);
                texture.minFilter = THREE.LinearFilter;
                mesh.position.set(0, 0, -10);
                // 将对象添加到场景中
                scene.add(mesh);
                reslove(true)
              },
              function (err){
                reject(err)
              }
            );
          } catch (e) {
            console.log(e);
            reject(e)
          }
        });
      };
    },
    // 点击事件处理函数
    cutImage(i) {
      this.setIndex = i;
      this.textureLoader.load(this.imgList[i].img, (texture) => {
        this.material.map = texture;
        this.material.needsUpdate = true;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

#three {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

::v-deep .el-loading-spinner {
  position: fixed;
}

.cutBox {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 0;
  background: #2a2a39;

  .cutImg {
    display: inline-block;
    width: 72px;
    margin-right: 9px;
    margin-left: 9px;

    .img {
      width: 72px;
      height: 72px;
      margin-bottom: 12px;
      background-repeat: no-repeat;
      background-position-x: 10%;
      background-position-y: 100%;
      background-size: 100%;
      border-radius: 8px;
      border: 1px solid #fff6fd;
    }

    .title {
      font-size: 14px;
      text-align: center;
      color: #bcbbc1;
    }
  }
  .setCutImg {
    .img {
      border: 2px solid #00e98f !important;
    }
    .title {
      color: #00e98f;
    }
  }
}
</style>
